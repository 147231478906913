AOS.init({
  duration: 1000,
  easing: 'ease-in-out',
  once: true,
});

//main-page


gsap.fromTo('.header__top', {
  opacity: 0,
  y: 20,
}, {
  delay: 1,
  duration: 1,
  opacity: 1,
  y: 0
});
gsap.fromTo('.header__bottom', {
  opacity: 0,
  y: -15,
}, {
  delay: 1,
  duration: 1,
  opacity: 1,
  y: 0
});
gsap.fromTo('.header__border', {
  opacity: 0,
  width: 0,
}, {
  delay: 1.5,
  duration: 1,
  opacity: 1,
  width: "100%"
});

if (document.querySelector('.banner')) {
  gsap.fromTo('.banner__bg-one', {
    opacity: 0,
    y: -1000,
  }, {
    duration: 1,
    opacity: 1,
    y: 17
  });
  gsap.fromTo('.banner__bg-two', {
    opacity: 0,
    y: -1000,
  }, {
    delay: .1,
    duration: 1,
    opacity: 1,
    y: -15
  });
  gsap.fromTo('.banner__slider', {
    opacity: 0,
    x: -50,
  }, {
    delay: 2,
    duration: 1,
    opacity: 1,
    x: 0
  });
  gsap.fromTo('.banner__img', {
    opacity: 0,
    x: 200,
    scale: 1.3
  }, {
    delay: 2,
    duration: 1,
    opacity: 1,
    x: 0,
    scale: 1
  });
  gsap.fromTo('.banner__img-phone', {
    opacity: 0,
    x: 50,
  }, {
    delay: 2,
    duration: 1,
    opacity: 1,
    x: 0,
  });
}



//second-page

if (document.querySelector('.second-header')) {
  gsap.fromTo('.header__bg-one', {
    opacity: 0,
    y: -300,
  }, {
    duration: 1,
    opacity: 1,
    y: 15
  });
  gsap.fromTo('.header__bg-two', {
    opacity: 0,
    y: -300,
  }, {
    delay: .1,
    duration: 1,
    opacity: 1,
    y: 0
  });
}

//obj-page

if (document.querySelector('.obj-banner')) {
  gsap.fromTo('.obj-banner', {
    opacity: 0,
    y: -300,
  }, {
    delay: .1,
    duration: 1,
    opacity: 1,
    y: 15
  });
  gsap.fromTo('.breadcrumbs', {
    opacity: 0,
    x: 50
  }, {
    delay: 1.3,
    duration: 1,
    opacity: 1,
    x: 0
  });
  gsap.fromTo('.obj-banner__title', {
    opacity: 0,
    x: 50
  }, {
    delay: 1.3,
    duration: 1,
    opacity: 1,
    x: 0
  });
  gsap.fromTo('.title-before', {
    opacity: 0,
    x: -130
  }, {
    delay: 1.3,
    duration: 1,
    opacity: 1,
    x: -20
  });
  gsap.fromTo('.obj-banner__tab', {
    opacity: 0,
    x: -50,
  }, {
    delay: 1.3,
    duration: 1,
    opacity: 1,
    x: 0
  });
  gsap.fromTo('.obj-banner__info', {
    opacity: 0,
    x: 50
  }, {
    delay: 1.3,
    duration: 1,
    opacity: 1,
    x: 0
  });

}

// gasp.to('.banner__info')
