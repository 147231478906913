
    if (document.querySelector('.obj-banner__tab')) {
        //переключение табов we-can
        const weCanTabsBtnOne = document.querySelectorAll(".obj-banner__tab-btn");
        const weCanTabsItemsOne = document.querySelectorAll(".obj-banner__tab-content");

        weCanTabsBtnOne.forEach(onTabClickOne);

        function onTabClickOne(item) {
            item.addEventListener("click", function () {
                let currentBtn = item;
                let tabId = currentBtn.getAttribute("data-tab");
                let currentTab = document.querySelector(tabId);

                if (!currentBtn.classList.contains('active')) {
                    weCanTabsBtnOne.forEach(function (item) {
                        item.classList.remove('active');
                    });

                    weCanTabsItemsOne.forEach(function (item) {
                        item.classList.remove('active');
                    });

                    currentBtn.classList.add('active');
                    currentTab.classList.add('active');
                }
            });
        }

        document.querySelector('.obj-banner__tab-btn').click();
    }

    if (document.querySelector('.obj-info')) {
        //переключение табов we-can
        const weCanTabsBtn = document.querySelectorAll(".obj-info__tab-btn");
        const weCanTabsItems = document.querySelectorAll(".obj-info__tab-content");

        weCanTabsBtn.forEach(onTabClick);

        function onTabClick(item) {
            item.addEventListener("click", function () {
                let currentBtn = item;
                let tabId = currentBtn.getAttribute("data-tab");
                let currentTab = document.querySelector(tabId);

                if (!currentBtn.classList.contains('active')) {
                    weCanTabsBtn.forEach(function (item) {
                        item.classList.remove('active');
                    });

                    weCanTabsItems.forEach(function (item) {
                        item.classList.remove('active');
                    });

                    currentBtn.classList.add('active');
                    currentTab.classList.add('active');
                }
            });
        }
    }