//select

const headerSelect = () => {
  const element = document.querySelector('.header__select'),
    choices = new Choices(element, {
      searchEnabled: false,
      itemSelectText: '',
    });
}

headerSelect();

const headerSelectPhone = () => {
  const element = document.querySelector('#header-select-2'),
    choices = new Choices(element, {
      searchEnabled: false,
      itemSelectText: '',
    });
}

headerSelectPhone();

//menu desktop-tablet

const header = document.querySelector('.header'),
  headerMenuItem = document.querySelectorAll('.header__menu-item'),
  headerMenuModal = document.querySelectorAll('.header__menu-modal');

headerMenuItem.forEach(item => {
  let currentItem = item,
    currentID = currentItem.getAttribute('data-header-item'),
    currentContent = document.querySelector(currentID);
  item.addEventListener('mouseenter', (e) => {

    if (currentItem.classList.contains('modalBtn')) {
      if (!currentItem.classList.contains('active')) {
        headerMenuItem.forEach(item => {
          item.classList.remove('active');
        });
        headerMenuModal.forEach(item => {
          item.classList.remove('active');
        });

        currentItem.classList.add('active');
        htop.classList.remove('pi');
        currentContent.classList.add('active');
      }
    }
  });
});

headerMenuModal.forEach(item => {
  item.addEventListener('mouseout', (e) => {
    let self = e.relatedTarget;
    if (self.classList.contains('header__menu-modal-before')) {
      headerMenuItem.forEach(item => {
        item.classList.remove('active');
      });
      headerMenuModal.forEach(item => {
        item.classList.remove('active');
      });
    }
  });

  item.addEventListener('click', (e)=>{
    let self = e.target;
    if (self.classList.contains('header__menu-modal-before')) {
      headerMenuItem.forEach(item => {
        item.classList.remove('active');
      });
      headerMenuModal.forEach(item => {
        item.classList.remove('active');
      });

    }
  });
});


//menu fablet-phone

const headerAccordions = document.querySelectorAll('.header__menu-item');

headerAccordions.forEach(item => {
  item.addEventListener('click', (e) => {
    const self = e.currentTarget,
      contentAll = document.querySelectorAll('.header__menu-modal'),
      content = self.querySelector('.header__menu-modal');

    if (self.classList.contains('accordion')) {

      headerAccordions.forEach(item => {
        if (item != self) {
          item.classList.remove('active');
        }
      });
      contentAll.forEach(item => {
        item.style.maxHeight = null;
      });

      self.classList.toggle('active');

      if (self.classList.contains('active')) {
        content.style.maxHeight = content.scrollHeight + "px";
      } else {
        content.style.maxHeight = null;
      }
    }
  });
});


//header-menu-open 

const menuOpenBtn = document.querySelector('.header__menu-btn'),
  menu = document.querySelector('.header__menu-phone-outer'),
  body = document.body,
  line = document.querySelector('.header__bottom');

menuOpenBtn.addEventListener('click', () => {
  menuOpenBtn.classList.toggle('active');
  menu.classList.toggle('active');
  body.classList.toggle('lock');
  if (!line.classList.contains('active')) {
    setTimeout(
      () => {
        line.classList.add('active');
      },
      700
    );
  } else {
    line.classList.remove('active');
  }
})


//header-logo and choices

const choic = document.querySelector('.choices'),
  htop = document.querySelector('.header__top');

choic.addEventListener('click', () => {
  htop.classList.add('pi');
});
